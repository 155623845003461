<template>

    <div>
        <div class="row">
              <div class="col crm-nahledy" style="">

                <div class="col-4book pt-4" id="col4book">
                  <Mikroblog ref="mikroblog1"
                                 :enable_editor="true"
                                 :itemtype="0"></Mikroblog>
               <!--   <mikroblog-app v-bind:current_user="current_user"
                                 v-bind:posts="posts"
                                 v-bind:partners="friends"
                                 ref="mikroblog1"
                                 :enable_editor="false"
                                 :itemtype="0"></mikroblog-app>-->
                </div>

              </div>
        </div>
    </div>

</template>

<script>

import Mikroblog from "./mikroblog/mikroblog";
import axios from "axios";
export default {
      name: "Community",
    components: {Mikroblog},
  mounted() {

        if (this.$store.getters.me.id>0 && this.$store.getters.me.company &&  this.$store.getters.me.company.status=='rejected' ){
          this.$router.push('/main/non-confirmed')
          return
        }

        console.log(' path ',this.$route.params.logintoken)
        if (this.$route.params.logintoken) {
          localStorage.setItem('user-token', this.$route.params.logintoken)
          axios.defaults.headers.common['Authorization'] = `Bearer ${this.$route.params.logintoken}`
        }

        //console.log("mounted com")
  }
}
</script>

<style scoped lang="scss">

.crm-nahledy{
  display: block;
  margin-right: calc(100vw - 860px);
  @media (min-width: 1150px){
    margin-right:300px;
  }

  padding:0 ;
}


</style>
